import React from 'react'

import { Hero } from '../../components/blog/hero'
import { PostSidebar } from '../../components/blog/post-sidebar'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import SEO from '../../components/seo'
import img1 from './images/blog34.jpg'
import styles from './post-grid.module.css'

const SuitableSnacks = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={'Proč si dávat pozor na ovocné šťávy?'}
        description={
          'Nápoje z ovoce a zeleniny jsou v dnešní době velice oblíbené a skvělé pro zpestření našeho jídelníčku.'
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: '24px 0' }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/suitable-snacks">
            Proč si dávat pozor na ovocné šťávy?
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero
              title="Proč si dávat pozor na ovocné šťávy?"
              date="12.09.23"
            />
            <div>
              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: '40px' }}
                alt="jídlo v krabicích"
              />
              <p className={styles.postText}>
                Nápoje z ovoce a zeleniny jsou v dnešní době velice oblíbené a
                skvělé pro<b> zpestření našeho jídelníčku. </b>I přes to, že ovocné a
                ovocnozeleninové šťávy nebo smoothies obsahují
                <b> méně prospěšné vlákniny </b>než celé ovoce, stále jsou
                <b>
                {' '}
                  dobrým zdrojem některých vitamínů, minerálních látek a enzymů.
                </b>{' '}
                Rozhodně je tedy lepší smoothie než ovoce žádné. Jsou ovšem
                věci, kvůli kterým bychom si měli
                <b> dávat na ovocné šťávy pozor. </b>
                Začněme však od začátku.
              </p>
              <h5 className={styles.postTitle}>
                Jaké jsou rozdíly mezi freshem a smoothie?
              </h5>

              <p className={styles.postText}>
                Fresh je <b> šťáva z ovoce nebo zeleniny, </b>kterou je možné
                získat pomocí lisu nebo odšťavňovače.
              </p>

              <p className={styles.postText}>
                Smoothie je tvořeno čerstvým
                <b> rozmixovaným ovocem či zeleninou </b>a tekutou složkou např.
                mlékem nebo vodou. Můžou se také přidat zahušťovací složky jako
                je jogurt, smetana či led.
              </p>
              <h5 className={styles.postTitle}>
                Jaké jsou výhody konzumace freshů a smoothies?
              </h5>

              <p className={styles.postText}>
                Fresh je skvělým zdrojem látek, které jsou důležité pro naše
                tělo, jako jsou <b>vitamíny a minerální látky. </b>Tyto látky
                jsou <b> rychle vstřebatelné </b>
                díky velice malému množství vlákniny. Díky těmto látkám mohou
                mít freshe pozitivní vliv na imunitu člověka, můžou také pomáhat
                snižovat množství cholesterolu v krvi a vyrovnávat hladinu cukru
                v krvi.
              </p>

              <p className={styles.postText}>
                Smoothie díky rozmixovaným slupkám z ovoce či zeleniny obsahuje{' '}
                <b> velké množství vlákniny</b>, tudíž nás{' '}
                <b>zasytí mnohem víc. </b>Zasycení může trvat déle, když přidáme
                ještě mléko nebo jogurt a třeba i nějaká semínka či ořechy.
                Vzhledem k tomu, že trávicí systém nemá s rozmělněním tolik
                práce, lze smoothie<b> snadněji strávit.</b>
              </p>
              <h5 className={styles.postTitle}>
                Na co si dát při konzumaci freshů a smoothies pozor?
              </h5>

              <p className={styles.postText}>
                U freshů je nevýhoda velmi<b> malého množství vlákniny, </b>
                která je pro naše tělo velmi důležitá. Tyto nápoje obsahují
                <b> ovocný cukr</b>, tzv. fruktózu, což zapříčiní, že do pár
                minut po vypití ucítíme<b> velký nával energie, </b>který se po
                zhruba hodině<b> promění v hlad. </b>Obsahují také velké
                množství<b> ovocných kyselin, </b>takže když freshe dostatečně
                nenaředíme, může to mít
                <b> negativní vliv na zubní sklovinu. </b>Je tedy dobré nápoj
                dostatečně naředit vodou, prospějeme tak nejen svým zubům, ale
                také celému organismu díky
                <b>
                  {' '}
                  snížení množství přijaté energie a jednoduchých sacharidů.{' '}
                </b>
                Samozřejmě také<b> pozor na kalorie </b>- 2 dcl ovocné šťávy je
                jako<b> 1 porce ovoce. </b>Sladké nápoje se pijí samy, takže
                tady opravdu pozor - fresh si dopřejte jako zpestření
                jídelníčku, protože ho máte rádi, případě chcete doplnit nějaké
                vitaminy.<b> Nic se ale nemá přehánět.</b>
              </p>

              <p className={styles.postText}>
                Smoothies obsahují naopak<b> velké množství vlákniny. </b>V
                případě přemíry vlákniny dochází ke špatné vstřebatelnosti
                některých minerálních látek a vitamínů, případně se také mohou
                vyskytnout žaludeční potíže a koliky - bolestivý stav v oblasti
                břicha.
                <b>
                  {' '}
                  Přemíra vlákniny ovšem nehrozí ve většině jídelníčků české populace
                </b>, nemusíte se tedy smoothieček obávat. Ideálně přimíchejte i nějaký mléčný výrobek a zdravé tuky, například jogurt a chia semínka. Takto si můžete vytvořit ideální snídani nebo svačinu. 
                I smoothies obvykle obsahují i několik kusů ovoce, proto to ani
                s nimi nepřehánějte.
              </p>
              <h5 className={styles.postTitle}>
                Jak si fresh nebo smoothie vyrobit doma?
              </h5>
              <p className={styles.postText}>
                Nyní si představíme dva recepty na fresh a smoothie nápoj.
              </p>
              <p className={styles.postText}>ŠŤÁVA (FRESH) Z ČERVENÉ ŘEPY:</p>

              <p className={styles.postText}>
                Suroviny:
                <br />
                500 g červené řepy
                <br />
                500 g mrkve
                <br />
                500 g jablek
                <br />
                Kousek čerstvého zázvoru (4 – 5 cm)
              </p>
              <p className={styles.postText}>
                Postup:
                <br />
                1) Červenou řepu, mrkev, jablka a zázvor okrájíme, u jablek
                odstraníme jádřince
                <br />
                2) Vše dáme do odšťavňovače a po odšťavení ihned podáváme
              </p>
              <p className={styles.postText}>ZELENÉ SMOOTHIE (2 porce):</p>

              <p className={styles.postText}>
                Suroviny:
                <br />
                2 zralé banány
                <br />
                1 zralé avokádo
                <br />
                2 hrsti červeného špenátu
                <br />
                Hrst čerstvé máty
                <br />
                40 g kešu oříšků
                <br />
                1 lžička čekankového nebo javorového sirupu či medu
                <br />
                Džus nebo voda
              </p>

              <p className={styles.postText}>
                Postup:
                <br />
                1) Kešu oříšky den předem namočíme, aby změkly
                <br />
                2) Banány, avokádovou dužinu, špenát, mátu a oříšky rozmixujeme
                dohladka
                <br />
                3) Zředíme džusem nebo vodou a podle chuti osladíme
                <br />
                4) Nalijeme do skleniček a ozdobíme čerstvým ovocem, popřípadě
                plátky kokosu či mandlí
              </p>

              <p className={styles.postText}>Tip:</p>

              <p className={styles.postText}>
                Do smoothie můžeme přidat i trošku nastrouhaného zázvoru nebo
                kousek řapíkatého celeru. Ke smoothie můžeme konzumovat dýňová
                semínka.
              </p>
              <h5 className={styles.postTitle}>A co je závěrem?</h5>
              <p>
                Smoothies a freshe jsou skvělým zpestřením našeho jídelníčku,
                ale rozhodně by<b> neměly nahrazovat celkový příjem tekutin</b>.
                Můžeme je tedy považovat pouze za<b> doplněk stravy </b> a
                dopřávat si je zhruba 2 – 3x týdně. Pokud byste si měli vybrat
                mezi odšťaveným ovocem nebo rozmixovanými kusy ovoce, vždy
                sáhněte po druhé variantě.
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default SuitableSnacks
